<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mt-4.mb-5.lh-18
		h1.mb-6 {{ $t('cookie.title') }}
		p.text-muted.mb-4 {{ $t('date.updated') }}: {{ updatedDate }}

		p.mb-6(v-html="$t('cookie.desc')")

		.mb-6(v-for='i in common' :key='i.title')
			span.anchor(:id='i.id')
			a.text-default(:href='`#${i.id}`')
				span.page-subtitle {{ i.title }}
			.mb-4(v-html='i.text')
			div(v-if='i.sub')
				slot(v-for='s in i.sub')
					p.f-16 - {{ s.title }}
					.mb-4(v-html='s.text')
		
		pw-btn-up
</template>

<script>
import moment from 'moment'
import {bus} from '@/main'
import PwBtnUp from '@/components/Buttons/PwBtnUp'

export default {
	metaInfo () { return {title: 'Cookie'}},
	components: {
		PwBtnUp
	},
	data() {
		let date = '2022-06-21';
		let day = moment(date).format('DD');

		return {
			updatedDate	: moment(date).format('DD MMMM YYYY').replace(day, `«${day}»`),
			version 	: 1.6
		}
	},
	computed: {
		common () { return bus.$t('cookie.common') }
	},
	created () {
		window.scrollTo(0,0);
	}
}
</script>
